<template>
  <div class="home">
    <img
      src="https://api.rpgplanner.hadd.fr/storage/dice.png"
      alt="RPG Planner"
      title="RPG Planner"
    />
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  components: {},
};
</script>

<style lang="scss">
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
