import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

axios.defaults.baseURL = 'https://api.rpgplanner.hadd.fr/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    party: null,
    signup: null,
  },
  getters: {
    getParty: (state) => state.party,
    getSignup: (state) => state.signup,
  },
  mutations: {
    setParty: (state, payload) => state.party = payload,
    setSignup: (state, payload) => state.signup = payload,
  },
  actions: {
    fetchParty: ({ commit }, id) => new Promise((resolve, reject) => {
      axios.get(`/parties/${id}`).then((response) => {
        commit('setParty', response.data.data);
        resolve(response);
      }).catch((error) => reject(error));
    }),
    fetchSignup: ({ commit }, id) => new Promise((resolve, reject) => {
      axios.get(`/signups/${id}`).then((response) => {
        commit('setSignup', response.data.data);
        resolve(response);
      }).catch((error) => reject(error));
    }),
    createSignup: ({ commit }, payload) => new Promise((resolve, reject) => {
      axios.post('/signups', payload).then((response) => {
        commit('setSignup', response.data.data);
        resolve(response);
      }).catch((error) => reject(error));
    }),
    toggleAvailability: ({ commit, dispatch }, payload) => new Promise((resolve, reject) => {
      const { signup, timeslot, party } = payload;
      axios.post(`signups/${signup}/available/${timeslot}`).then((response) => {
        commit('setSignup', response.data.data);
        dispatch('fetchParty', party);
        resolve(response);
      }).catch((error) => reject(error));
    }),
  },
  modules: {
  },
});
