import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    form: {
      name: 'What is your name ?',
      email: 'What is your email ?',
      confirm: 'Confirm',
    },
    display_timezone: 'Display in my timezone',
    instructions: 'Click on your column to choose your availabilities',
    time_slot: 'Time slot',
    works_for_everyone: 'This date works for everyone at the moment !',
    available: 'Available',
    unavailable: 'Unavailable',
    date_format: 'dddd MMMM Do YYYY HH:mm',
  },
  fr: {
    form: {
      name: 'Quel est votre nom ?',
      email: 'Quel est votre e-mail ?',
      confirm: 'Valider',
    },
    display_timezone: 'Afficher dans mon fuseau horaire',
    instructions: 'Cliquez sur la colonne pour indiquer vos disponibilités',
    time_slot: 'Créneau horaire',
    works_for_everyone: 'Cette date convient à tout le monde pour le moment !',
    available: 'Disponible',
    unavailable: 'Indisponible',
    date_format: 'dddd D MMMM YYYY HH:mm',
  },
};

const i18n = new VueI18n({
  locale: 'fr',
  messages,
});

export default i18n;
